require('cookieconsent');

require('normalize.css/normalize.css');
require('./styles/index.scss');
require('cookieconsent/build/cookieconsent.min.css');

document.addEventListener("DOMContentLoaded", () => {

    cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#000"
            },
            "button": {
                "background": "#f07b05"
            }
        },
        "showLink": false,
        "theme": "edgeless",
        "position": "bottom-right",
        "content": {
            "message": "Tento web používá k&nbsp;poskytování služeb, personalizaci reklam a&nbsp;analýze návštěvnosti soubory cookie. Používáním tohoto webu s&nbsp;tím souhlasíte.",
            "dismiss": "Beru na vědomí! o7"
        }
    });
});
